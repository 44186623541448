import React from 'react';

import Layout from '../components/layout';
import Logo from '../components/logo';
import Menu from '../components/menu';
import Section, { InnerSection } from '../components/section';
import Slider from '../components/slider';
import Footer from '../components/footer';
import { ModalContext } from '../components/context/Modal';

export default function IndexPage(props) {
  return (
    <Layout>
      <div className="header sub-page who-we-are-header">
        <Menu {...props} />
        <div className="hero-section sub-page">
          <span className="tagline">Who We Are</span>
          <span className="sub-header">
            SMG is a small boutique property management company, located in
            Columbus, Ohio. Our small size enables us to offer personalized
            attention to our client’s properties.
          </span>
        </div>
      </div>
      <div className="body-container sub-page who-we-are">
        <div className="background">
          <div className="row">
            <InnerSection
              title="Approach to Investment"
              style={{ margin: 0 }}
              body={
                <div>
                  <p>
                    The Scioto Management Group seeks Real Estate Properties
                    with untapped potential. We look for strong markets where we
                    can acquire properties with unrealized promise and value and
                    ultimately generate a significant profit. We know when to
                    re-position, when to renovate and when to leave well enough
                    alone. From the long-term marketing to the day-to-day
                    maintenance, a hands-on approach will guide our properties
                    toward their maximum potential.
                  </p>
                  <p>
                    This strategy demands restraint. The patience to wait until
                    all of the elements fit into place. The confidence to ignore
                    trendy thinking. We adhere to this fundamental formula, with
                    its emphasis on discipline and detail.
                  </p>
                </div>
              }
            />
          </div>
          <div className="row">
            <InnerSection
              title="Property Management"
              body="Through a strong hands-on approach to property management, sound approach to business, real estate experience and entrepreneurial spirit, along with relationship-based team energy and the dedication of its associates, the Scioto Management Group provides to its investors, owners, and clients the benefits of high productivity, low vacancy rates, as well as excellent financial controls and timely reporting."
            />
            <InnerSection
              title="Our Residents"
              body="We take Serving our Resident and our Tenants very seriously.  We know that our residents and tenants are coming to us to help them with getting one of their most expensive purchases / investments.  They also expect that spending their hard-earned dollars in one of our properties will provide them with a peace of mind that we have their back and will take care of their home or place of business."
            />
          </div>
        </div>
        <Section
          title="History"
          body="Our philosophy is to offer a Personal and High Level of Professional and Sophisticated Property Management and Investment Acquisition Services without the high overhead cost of a large company,  The company was founded in early 2003 and took our name from the Scioto River, the longest river in Ohio and historically provided a profitable route for commerce to travel."
          backgroundName="history"
          left
        />
        <div className="row">
          <div className="contact-section">
            <span className="text">Interested in learning more?</span>
            <ModalContext.Consumer>
              {({ isModalOpen, setModalState }) => (
                <a onClick={() => setModalState(true)}>
                  <button className="contact-button">Contact Us</button>
                </a>
              )}
            </ModalContext.Consumer>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
